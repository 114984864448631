<template>
	<div class="content">
		<headers :marage="159" :selectIndex="2">
			<!-- <Header /> -->
		</headers>
		<div class="container">
			<!-- 轮播图 -->
			<div class="banner"></div>
			<!--  -->
			<div class="cloud" style="margin-top: 30px;">
				<div class="cloud-yun">
					<h2>云课堂系统</h2>
					<span>课程内容丰富，包含17000多集实战录播实训课，众多知名大学在线MBA，百位名师在线问答，众多优选线下好课，名师直播等</span>
					<img src="../../assets/img/productcenterimg/heng.png" alt="" />
				</div>
			</div>
			<!--  -->
			<div>
				<div class="module">
					<div class="module-icon">
						<div>
							<div class="module-wenzi"
								style="background: linear-gradient(90deg, #e8e6f2 0%, #f4f3f8 100%)">
								<div class="tu">
									<img src="../../assets/img/productcenterimg/ic.kecheng.png" alt="" />
								</div>
								<div class="module-wenziZ">
									<b>测</b>
									<span>企业诊断，人才测评，让企业对员工能力一眼看穿</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="module">
					<div class="module-icon">
						<div>
							<div class="module-wenzi"
								style="background: linear-gradient(90deg, #f2e6e6 0%, #f8f3f3 100%)">
								<div class="tu">
									<img src="../../assets/img/productcenterimg/ic.jigou.png" alt="" />
								</div>
								<div class="module-wenziZ">
									<b>学</b>
									<span>4大类，21个系列，17000+集实战课程保障企业有课所选，针对性强</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="module">
					<div class="module-icon">
						<div>
							<div class="module-wenzi"
								style="background: linear-gradient(90deg, #e6f0f2 0%, #f3f8f7 100%)">
								<div class="tu">
									<img src="../../assets/img/productcenterimg/ic.chengjiu.png" alt="" />
								</div>
								<div class="module-wenziZ">
									<b>练</b>
									<span>边学边练+心得思考+改进计划，在线化汇总让管理者看得到改变，看得到结果</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="module">
					<div class="module-icon">
						<div>
							<div class="module-wenzi"
								style="background: linear-gradient(90deg, #e6ebf2 0%, #f3f4f8 100%)">
								<div class="tu">
									<img src="../../assets/img/productcenterimg/ic.renwu.png" alt="" />
								</div>
								<div class="module-wenziZ">
									<b>考</b>
									<span>在线设计考题，边学边考，边考边总结，考试成绩与绩效、薪酬、晋升挂钩，给企业提供决策参考</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="module">
					<div class="module-icon">
						<div>
							<div class="module-wenzi"
								style="background: linear-gradient(90deg, #f2e6e6 0%, #f8f3f3 100%)">
								<div class="tu">
									<img src="../../assets/img/productcenterimg/ic.zaixian.png" alt="" />
								</div>
								<div class="module-wenziZ">
									<b>评</b>
									<span>数据化、报表化、可视化，让管理有据可依</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="module">
					<div class="module-icon">
						<div>
							<div class="module-wenzi" style="
                  background: linear-gradient(90deg, #f2eee6 0%, #f8f7f3 100%);
                  marginbottom: 30px;
                ">
								<div class="tu">
									<img src="../../assets/img/productcenterimg/ic.yuyue.png" alt="" />
								</div>
								<div class="module-wenziZ">
									<b>奖</b>
									<span>好的表现+好的建议要鼓励，坏的习惯要惩罚，奖优罚劣，奖要奖的心花怒放，罚要罚的胆战心惊</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!--  -->
			<div class="module-two">
				<div class="cloud">
					<div class="cloud-yun">
						<h2 style="color: #fff">轻OA-高效办公平台</h2>
						<span
							style="color: #fffefe; opacity: 0.8;">集企业公告、工作汇报、考勤打卡、在线沟通、语音电话、企业网盘等实用功能，智能化保证企业高效办公</span>
						<img src="../../assets/img/productcenterimg/heng.png" alt="" />
					</div>
				</div>
				<div class="module-three">
					<div class="module-box">
						<img src="../../assets/img/productcenterimg/ic_7.png" alt="" />
						<div class="wordyi">企业信息公告</div>
						<div class="worder">快讯、新闻，灵活发布</div>
					</div>
					<div class="module-box">
						<img src="../../assets/img/productcenterimg/ic_8.png" alt="" />
						<div class="wordyi">智能在线沟通</div>
						<div class="worder">组织架构在线化实时内部沟通</div>
					</div>
					<div class="module-box">
						<img src="../../assets/img/productcenterimg/ic_9.png" alt="" />
						<div class="wordyi">员工考勤管理</div>
						<div class="worder">在线打卡，高效考勤</div>
					</div>
					<div class="module-box">
						<img src="../../assets/img/productcenterimg/ic_10.png" alt="" />
						<div class="wordyi">工作汇报在线化</div>
						<div class="worder">日报、周报、月报，在线追踪</div>
					</div>
					<div class="module-box">
						<img src="../../assets/img/productcenterimg/ic_11.png" alt="" />
						<div class="wordyi">多管理员</div>
						<div class="worder">权限分配责任共担</div>
					</div>
					<div class="module-box" style="marginbottom: 25px">
						<img src="../../assets/img/productcenterimg/ic_12.png" alt="" />
						<div class="wordyi">天眼系统</div>
						<div class="worder">实时追踪员工办公、学习行为</div>
					</div>
					<div class="module-box">
						<img src="../../assets/img/productcenterimg/ic_8.png" alt="" />
						<div class="wordyi">视频会议</div>
						<div class="worder">多人会议系统，实时高效</div>
					</div>
					<div class="module-box" style="marginbottom: 25px">
						<img src="../../assets/img/productcenterimg/ic_10.png" alt="" />
						<div class="wordyi">企业直播</div>
						<div class="worder">实时政策，全球秒达</div>
					</div>
				</div>
			</div>
			<div>
				<div class="cloud">
					<div class="cloud-yun">
						<h2>大数据管测系统</h2>
						<span>自主研发的“企业诊断工具+人才测评系统+全方位评价系统”，让企业管理，人才选拔，业绩增长，有据可依</span>
						<img src="../../assets/img/productcenterimg/heng.png" alt="" />
					</div>
				</div>
			</div>
			<div class="module-three">
				<div class="module">
					<div class="module-icon">
						<div>
							<div class="module-wenzi">
								<div class="tu">
									<img src="../../assets/img/productcenterimg/ic_13.png" alt="" />
								</div>
								<div class="module-wenziZ">
									<b>360企业诊断</b>
									<span>企业问题一针见血</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="module">
					<div class="module-icon">
						<div>
							<div class="module-wenzi">
								<div class="tu">
									<img src="../../assets/img/productcenterimg/ic_14.png" alt="" />
								</div>
								<div class="module-wenziZ">
									<b>人才测评</b>
									<span>优秀人才一眼看穿</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="module">
					<div class="module-icon">
						<div>
							<div class="module-wenzi">
								<div class="tu">
									<img src="../../assets/img/productcenterimg/ic_15.png" alt="" />
								</div>
								<div class="module-wenziZ">
									<b>报表分析</b>
									<span>通过数据反馈评估团队现状</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="module">
					<div class="module-icon">
						<div>
							<div class="module-wenzi">
								<div class="tu">
									<img src="../../assets/img/productcenterimg/ic_16.png" alt="" />
								</div>
								<div class="module-wenziZ">
									<b>智慧树</b>
									<span>游戏化学习，终身陪伴</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="module">
					<div class="module-icon">
						<div>
							<div class="module-wenzi">
								<div class="tu">
									<img src="../../assets/img/productcenterimg/ic_17.png" alt="" />
								</div>
								<div class="module-wenziZ">
									<b>多维度PK</b>
									<span>内部PK、行业PK、城市PK,成为冠军</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- <div class="module">
					<div class="module-icon">
						<div>
							<div class="module-wenzi">
								<div class="tu">
									<img src="../../assets/img/productcenterimg/ic_18.png" alt="" />
								</div>
								<div class="module-wenziZ">
									<b>消息管理</b>
									<span> 消息群发、提醒，历史记录查询</span>
								</div>
							</div>
						</div>
					</div>
				</div> -->
			</div>
			<!--  -->
		</div>
	</div>
</template>

<script>
	import headers from "../../components/header.vue";
	export default {
		components: {
			headers,
		},
	};
</script>

<style lang="stylus" scoped>
	.module-three {
		display flex 
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-evenly;

		.module-box {
			width 1.67rem 
			height 1.3rem 
			text-align center // background-color rgba(170,170,170,0.2)
			background url(../../assets/img/teacherimg/bg.png) 
			border-radius 8px 
			margin-top 20px
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}

		img {
			width 0.35rem 
			height 0.35rem 
			argin-top 20px
		}

		.wordyi {
			color #fff 
			font-size 16px 
			line-height 20px 
			margin-top 11px
		}

		.worder {
			font-size: 12px
			font-weight: 400
			color: #ffffff
			line-height: 21px
			text-align center 
			opacity: 0.7
		}
	}

	.van-grid-item__content {
		background-color rgba(0, 0, 0, 0) !important
	}

	.box {
		display flex
	}

	.banner {
		height 1.60rem 
		width 100% 
		background url(../../assets/img/productcenterimg/banner.png) no-repeat 
		background-size 100%
	}

	.cloud {
		display flex 
		justify-content center 
		text-align center 
		line-height 20px 
		.cloud-yun {
			margin-top 0.3rem 
			h2 {
				font-size: 0.19rem;
				font-family: PingFang SC
				font-weight: 800;
				color: #212121
				line-height: 26px;
			}

			span {
				display block 
				line-height: 18px 
				color #999 
				font-weight: 400 
				width 2.9rem 
				margin-top 0.1rem
			}

			img {
				width 0.38rem 
				height 0.03rem
			}
		}
	}

	.module {
		display flex 
		width 100% 
		justify-content: center 
		margin-top 0.1rem 
		.module-icon {
			display flex 
			.module-wenzi {
				border-radius 10px 
				width: 3.45rem 
				height: 0.9rem 
				justify-content: space-evenly 
				display flex 
				align-items: center 
				background linear-gradient(90deg, #E8E6F2 0%, #F4F3F8 100%) 
				margin-top: 2px;

				b {
					font-size: 0.17rem;
					// font-family: PingFang SC;
					font-weight: 600;
					color: #212121
					line-height: 0.25rem;
				}

				span {
					display block 
					color #212121 
					line-height: 0.18rem;
				}
			}
		}
	}

	.tu {
		height 0.33rem 
		margin-left 13px 
		img {
			width 0.35rem 
			height 0.35rem
		}
	}

	.module-wenziZ {
		display flex 
		width 2.3rem 
		align-items: flex-start 
		flex-direction: column 
		margin-right: 20px
		margin-top: 2px
	}

	.module-wenzi:nth-child(2) {
		width: 3.45rem 
		height: 0.7rem 
		margin-left 10px 
		justify-content: space-evenly 
		display flex 
		align-items: center 
		background: linear-gradient(90deg, #F2E6E6 0%, #F8F3F3 100%);
	}

	.module-two {
		background url(../../assets/img/productcenterimg/bg_one.png) 
		background-size 100% 
		margin-top 0.2rem
	}

	.module-three {
		padding-bottom 0.3rem 
		.module-wenzi {
			background #fff !important 
			box-shadow: 2px 1px 16px 2px rgba(153, 153, 153, 0.15);
		}
	}
</style>
